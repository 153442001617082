@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// main styles

.marketplace {
  margin: auto;
  margin-top: 2rem;
  color: white;
  max-width: 1200px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
    margin-top: 30px;

    @media (max-width: 876px) {
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;

      @media (max-width: 820px) {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
      }
    }

    &__transactions-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #232449;
      border-radius: 16px;
      padding: 40px 0;

      @media (max-width: 820px) {
        background-color: transparent;
      }

      &__buttons-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 0 30px;
        margin: 14px 0;

        @media (max-width: 576px) {
          padding: 0 10px;
        }

        h2 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        &__button {
          display: flex;
          align-items: center;
          margin-right: 10px;
          padding-bottom: 4px;
          cursor: pointer;

          img {
            margin-right: 6px;
          }
        }

        .active {
          border-bottom: 4px solid #83fff8;

          p {
            color: white;
          }
        }
      }

      &__listed-items-container,
      &__sold-items-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__see-more {
          display: flex !important;
          align-items: center;
          margin-top: 20px;
          color: #83fff8 !important;
          cursor: pointer;

          @media (max-width: 576px) {
            width: 100%;
            justify-content: flex-end;
            margin-right: 20px;
          }

          p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 29px;
            margin: 0;
          }

          svg {
            height: 20px;
            width: 20px;
          }
        }

        h1 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          margin-bottom: 6px;
        }

        h2 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 6px;
        }

        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #76779a;
          margin: 0;
        }

        &__item {
          display: grid;
          grid-template-columns: minmax(75px, 1fr) 4fr 2fr;
          gap: 10px;
          border-top: 1px solid #2e304a;
          border-bottom: 1px solid #2e304a;
          padding: 14px 30px;
          cursor: pointer;

          @media (max-width: 820px) {
            padding: 10px;
            background-color: #232449;
            border-radius: 5px;
            border: none;
            margin-bottom: 7px;
          }

          .rarity-mobile {
            display: none;

            @media (max-width: 1024px) {
              display: block;
            }
          }

          .rarity-desktop {
            @media (max-width: 1024px) {
              display: none;
            }
          }

          .info {
            display: grid;
            grid-template-columns: 2fr 1fr;

            @media (max-width: 1024px) {
              display: flex;
              flex-direction: column;
            }
          }

          .users {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
          }

          &-nft-container {
            position: relative;

            img {
              height: 64px;
              width: 64px;
              object-fit: cover;
            }
          }

          &-nft {
            @media (max-width: 1024px) {
              margin-bottom: 10px;
            }
          }

          &-detail {
            display: flex;
            flex-direction: column;

            &-class {
              background-color: #ad2336;
              color: white;
              margin-bottom: 4px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              padding: 4px 6px;
              border-radius: 4px;
              width: fit-content;

              @media (max-width: 1024px) {
                margin-bottom: 10px;
              }
            }
          }

          &-seller {
            display: flex;
            flex-direction: column;
          }

          &-buyer {
            display: flex;
            flex-direction: column;
          }

          &-price {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            p {
              text-align: right;
            }

            &-mcrt {
              display: flex;
              align-items: center;
              margin-bottom: 2px;

              h2 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: #83fff8;
                margin: 0;
              }

              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

  &__data-container {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 50px auto;

    @media (max-width: 776px) {
      margin: 20px auto;
      flex-direction: column;
    }

    &-data {
      display: flex;
      align-items: center;

      @media (max-width: 576px) {
        padding: 30px 0.7rem 0;
      }

      &__text {
        color: #83fff8;

        span {
          margin-right: 10px;
        }

        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          opacity: 0.7;
          margin-bottom: 6px;
          color: #fff;
        }

        h1 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 0.01em;

          @media (max-width: 576px) {
            font-size: 26px;
            text-align: center;
          }
        }
      }
    }

    img {
      height: 82px;
      width: 82px;
    }
  }

  &__time-btn-container {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    margin: 50px auto;

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #76779a;
      padding: 0 6px;
      cursor: pointer;
    }

    .active {
      border-bottom: 4px solid white;

      p {
        color: white;
      }
    }
  }

  @media (max-width: 576px) {
    left: 5%;
  }
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 49px;

  @media (max-width: 576px) {
    padding: 0;
    margin: 0;
  }

  &__banner {
    border-radius: 20px;
    width: 100%;
    padding: 4rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-image: url('../../assets//nft-banner.png');
    background-size: cover;
    background-position: right;

    @media (max-width: 991px) {
      padding: 3rem 2rem;
    }

    @media (max-width: 576px) {
      background-image: url('../../assets//nft-banner-mobile.png');
      padding: 1rem;
      height: 230px;
      margin-bottom: 1rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      @media (max-width: 991px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &--info {
      max-width: 500px;
      margin-bottom: 20px;

      @media (max-width: 991px) {
        max-width: 400px;
      }

      h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 43px;
        letter-spacing: 0.01em;
        color: #ffffff;
        margin-bottom: 20px;
      }

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        opacity: 0.7;
      }

      @media (max-width: 576px) {
        h1 {
          font-size: 32px;
          margin-top: 20px;
        }

        p {
          font-size: 12px;
        }

        br {
          display: none;
        }
      }

      h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.04em;
        color: #ffffff;
        opacity: 0.7;
      }
    }
    &--pages {
      display: flex;
      width: 100%;

      @media (max-width: 991px) {
        width: auto;
      }

      &--desktop {
        display: flex !important;
      }
      &--mobile {
        display: flex !important;
        justify-content: space-between;
      }
      &--tablet {
        display: flex;
        flex-direction: column;

        button {
          margin-right: 0;
        }
      }

      &--btnInventory {
        background-color: transparent !important;
        border: 2px solid #83fff8 !important;
        color: #83fff8 !important;
      }
      button {
        height: 56px;
        background: #83fff8;
        border-radius: 8px;
        border-width: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 18px 36px;
        margin-right: 1rem;
        display: flex;
        justify-content: flex-end;
        text-align: center;
        @media (max-width: 991px) and (min-width: 577px) {
          width: 158px;
          margin-bottom: 16px;
          padding: 0px;
          justify-content: center;
          align-items: center;
        }
        @media (max-width: 576px) {
          margin-right: 0px;
        }
      }
    }
  }

  &__marketdata {
    margin-top: 1rem;
    background: #191a47;
    border-radius: 16px;
    width: 100%;
    padding: 1.5rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    .nav-tabs {
      border-bottom: 1px solid #282949;
    }
    .nav-link {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #5c5f97;
    }
    .nav-tabs .nav-link {
      background-color: transparent !important;
      border-width: 0px;
      color: #5c5f97;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      color: white;
      background-color: transparent;
      border-width: 0px;
      border-bottom: 2px solid #83fff8;
    }
    &--marketInfo {
      display: flex;
      flex-direction: row;
      padding: 1.5rem;
      align-items: center;
      justify-content: center;
      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: flex-start;
      }
      &--infoItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        @media (max-width: 576px) {
          width: 100%;
          justify-content: flex-start;
        }
        img {
          width: 82px;
          height: 82px;
          @media (max-width: 576px) {
            width: 60px;
            height: 60px;
          }
        }
        &--infoText {
          flex-direction: column;
          display: flex;
          @media (max-width: 576px) {
            margin-left: 0.5rem;
          }
          h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.01em;
            color: #ffffff;
          }
          h5 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            opacity: 0.7;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  &__itemList {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    color: #83fff8;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    @media (max-width: 576px) {
      flex-direction: column;
    }
    h5 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #ffffff;
      opacity: 0.9;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }
    .nav-tabs {
      border-bottom: 1px solid #282949;
    }
    .nav-link {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #5c5f97;
    }
    .nav-tabs .nav-link {
      background-color: transparent !important;
      border-width: 0px;
      color: #5c5f97;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      color: white;
      background-color: transparent;
      border-width: 0px;
      border-bottom: 2px solid #83fff8;
    }
    &--listed {
      width: 49.5%;
      display: flex;
      flex-direction: column;
      margin-right: 1%;
      background: #191a47;
      border-radius: 16px;
      @media (max-width: 576px) {
        width: 100%;
      }
      &--details {
        width: 100%;
        display: flex;
        height: 86px;
        align-items: center;
        justify-content: center;
      }
    }
    &--sold {
      width: 49.5%;
      display: flex;
      flex-direction: column;
      background: #191a47;
      border-radius: 16px;
      @media (max-width: 576px) {
        width: 100%;
      }
      &--details {
        width: 100%;
        display: flex;
        height: 86px;
        align-items: center;
        justify-content: center;
        @media (max-width: 576px) {
          justify-content: flex-end;
        }
      }
    }
  }
}
