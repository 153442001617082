@font-face {
  font-family: 'Colus';
  src: url('../../assets/fonts/colus/Colus-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/futura-pt/FuturaPTBook.otf') format('opentype'),
    url('../../assets/fonts/futura-pt/FuturaPTMedium.otf') format('opentype'),
    url('../../assets/fonts/futura-pt/FuturaPTBold.otf') format('opentype');
}

.outer-dao__container {
  font-family: 'Futura PT', sans-serif;
  color: #fff;
  background-color: #08072b;
  position: relative;
  overflow: hidden;
}

.dao__container {
  width: 90%;
  margin: 0 auto;
  max-width: 1266px;
  & + & {
    margin-top: 90px;
  }
}

.dao-info {
  background-color: #11113a;
  border-radius: 30px;
  position: relative;
  z-index: 10;
}

.hero-section {
  width: 100%;
  position: relative;
  z-index: 0;
  height: fit-content;
  position: relative;
  padding-top: 150px;
  padding-bottom: 100px;
  background: url('../../assets/images/dao/hero-bg.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;

  & .hero-gradient-top {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background: linear-gradient(
      90deg,
      #03082f 0.27%,
      rgba(3, 8, 47, 0.92) 61.92%,
      rgba(3, 8, 47, 0) 99.73%
    );
  }

  & .hero-gradient-bottom {
    width: 100%;
    height: 800px;
    position: absolute;
    left: 0;
    bottom: -200px;
    z-index: -2;
    background: linear-gradient(180deg, rgba(12, 2, 24, 0) 0%, #08072b 50.13%);
  }

  & .hero-gradient-radial {
    position: absolute;
    top: 0;
    left: 5%;
    z-index: -1;
    width: 750px;
    height: 750px;
    border-radius: 750px;
    opacity: 0.6;
    background: rgba(39, 72, 129, 0.8);
    filter: blur(212px);
  }
}

.hero__content {
  max-width: 900px;
  & h4 {
    font-size: 30px;
  }
}

.badge-dao {
  background-color: #4457b84d;
  border-radius: 30px;
  width: fit-content;
  padding: 1rem 1.5rem;
  color: #98fff9;
  font-size: 1rem;
}

.hero__heading {
  font-family: 'Colus', sans-serif;
  font-size: 92px;
  line-height: 90px;
  padding-top: 24px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.75) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 2.6px 5.25px rgba(0, 0, 0, 0.5));
}

.subtext {
  font-size: 22px;
  padding: 24px 0;
  width: 85%;

  & p {
    padding-top: 24px;
  }
}

.subtext__divider {
  width: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(85, 109, 224, 1) 50%,
    rgba(85, 109, 224, 0) 100%
  );
  height: 1px;
}

.dao__btn {
  width: 258px;
  text-wrap: nowrap;
  white-space: nowrap;
  background-color: #98fff9;
  color: #03082f;
  font-size: 22px;
  font-weight: 600;
  padding: 16px 38px;
  border-radius: 6px;
}

.dao__content {
  position: relative;
  padding: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    border: 2px solid transparent;
    background: linear-gradient(to bottom, #b591f242, transparent) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.dao__title {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 30px;

  & h2 {
    font-size: 36px;
    text-transform: capitalize;
    font-family: 'Colus', sans-serif;
  }
}

.dao-card {
  background-color: #0c0c29;
  border-radius: 16px;
  width: 100%;

  & + & {
    margin-top: 30px;
  }
}

.dao-card__content {
  padding: 20px 30px 30px 30px;

  & > h4 {
    color: #98fff9;
    font-size: 30px;
  }

  & > .dao-card__divider {
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, #556de0, #556de000);
    margin: 16px 0;
  }

  & > .dao-card__list {
    & li {
      list-style-type: disc;
      font-size: 24px;
      font-style: normal;
      font-weight: 450;

      & .item-title {
        color: #98fff9;
        font-weight: 700;
      }
    }
  }
}

.dao-banner {
  display: flex;
  height: 360px;
  position: relative;
  z-index: 1;
  & .dao-banner__img-container {
    width: 30%;
    border-radius: 0px 30px 30px 0px;
    overflow: hidden;
    position: relative;
    justify-self: stretch;
    height: 100%;
    background-color: gray;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0px 30px 30px 0px;

      border: 1px solid transparent;
      background: linear-gradient(to bottom, #b591f242, transparent) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    & > img {
      width: auto;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }
}

.dao-banner__content {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
  border-radius: 30px 0px 0px 30px;
  background: linear-gradient(98deg, #2e285a 10.09%, #1a1844 100.25%),
    linear-gradient(255deg, #57186d 4.37%, #2a0d4e 99.01%),
    rgba(45, 44, 134, 0.8);
  max-width: 810px;
  padding: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px 0px 0px 30px;
    border: 2px solid transparent;
    border-right: 0;
    background: linear-gradient(to bottom, #b591f242, transparent) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  & > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 18.738px;
    font-style: normal;
    font-weight: 450;
    font-family: Futura PT;
    line-height: normal;
    letter-spacing: 0.937px;
    text-transform: uppercase;
  }

  & > h3 {
    color: #fff;
    font-family: Futura PT;
    font-size: 36px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.18px;
  }
}
.dao-cta-gradient {
  background: linear-gradient(180deg, #08072b 30%, rgba(12, 2, 24, 0));
  min-height: 400px;
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: -10;
}
.dao-cta-bottom-text {
  height: fit-content;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  z-index: -10;

  & span {
    text-wrap: nowrap;
    white-space: nowrap;
    text-align: center;
    font-family: Colus;
    font-size: 210.028px;
    font-style: normal;
    font-weight: 400;
    line-height: 262.535px; /* 125% */
    color: rgba(255, 255, 255, 0.1);
  }
}

.dao-cta-section {
  background: url(../../assets/images/dao/cta-bg.png),
    linear-gradient(
      270deg,
      rgba(12, 2, 24, 0) 81.51%,
      rgba(12, 2, 24, 0) 81.51%,
      rgba(12, 2, 24, 0.9) 100%
    ),
    linear-gradient(90deg, rgba(12, 2, 24, 0) 81.04%, rgba(12, 2, 24, 0.9) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.dao-cta {
  padding-top: 200px;
  padding-bottom: 250px;
  display: flex;
  position: relative;
  gap: 8px;

  & .dao-cta__img-container {
    flex-shrink: 0;
    position: relative;
    right: -50px;
    max-width: 500px;
    width: 30%;
    & img {
      max-width: 100%;
    }
  }
}

.dao-cta__content {
  max-width: 826px;
  width: 70%;
  & h2 {
    font-family: Colus;
    font-size: 92px;
    line-height: 90px; /* 97.826% */
    background: linear-gradient(
      180deg,
      #fff 0%,
      rgba(255, 255, 255, 0.75) 100%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & p {
    font-size: 30px;
    line-height: 38px; /* 126.667% */
    padding-bottom: 30px;
  }
}

@media (max-width: 1280px) {
  .hero__heading {
    font-size: 72px;
    line-height: 70px;
  }

  .dao-cta__content {
    & h2 {
      font-size: 72px;
      line-height: 70px;
    }
  }
}

@media (max-width: 1024px) {
  .hero__heading {
    font-size: 65px;
    line-height: 65px;
    padding-top: 16px;
  }

  .hero-section {
    padding-top: 50px;
    & .hero-gradient-top {
      width: 100%;
    }
  }

  .dao-banner__content {
    & > h3 {
      font-size: 32px;
    }
  }

  .dao-cta {
    & .dao-cta__img-container {
      right: 0;
    }
  }

  .dao-cta__content {
    & h2 {
      font-size: 58px;
      line-height: 58px;
    }

    & p {
      font-size: 24px;
      line-height: 24px; /* 126.667% */
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    & .hero-gradient-radial {
      top: 120px;
      width: 450px;
      height: 450px;
      left: 0;
    }
  }

  .hero__heading {
    font-size: 40px;
    line-height: 50px;
  }

  .hero__content {
    & h4 {
      font-size: 24px;
    }
  }

  .subtext {
    font-size: 18px;
    padding: 12px 0;
    & p {
      padding-top: 12px;
    }
  }

  .dao__content {
    padding: 20px;
  }
  .dao__title {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h2 {
      font-size: 28px;
      width: 100%;
      text-align: center;
    }
  }

  .dao-card__content {
    padding: 20px;

    & > h4 {
      font-size: 22px;
      text-align: center;
    }

    & > .dao-card__list {
      & li {
        font-size: 18px;
      }
    }
  }

  .dao-banner {
    height: 250px;
  }

  .dao-banner__content {
    padding: 20px;
    & > p {
      font-size: 16px;
    }

    & > h3 {
      font-size: 22px;
    }
  }

  .dao-cta {
    padding-top: 100px;
    padding-bottom: 150px;
    & .dao-cta__img-container {
      display: none;
    }
  }

  .dao-cta__content {
    width: 100%;
    & h2 {
      font-size: 40px;
      line-height: 40px;
    }

    & p {
      font-size: 24px;
      line-height: 24px; /* 126.667% */
    }
  }
}

@media (max-width: 640px) {
  .hero__heading {
    font-size: 36px;
    line-height: 50px;
  }
  .subtext {
    width: 100%;
  }

  .dao__btn {
    font-size: 18px;
    width: 160px;
    padding: 12px 16px;
  }
  .dao-banner {
    height: 200px;
  }

  .dao-banner__content {
    padding: 20px;
    & > p {
      font-size: 12px;
      margin-bottom: 8px;
    }

    & > h3 {
      font-size: 16px;
    }
  }
}
