.verify-container {
  max-width: 600px;
  margin: 80px auto 40px;
  color: white;
  padding: 40px;
  background: #232449;
  border-radius: 10px;

  @media only screen and (max-width: 500px) {
    padding: 40px 20px;
  }

  &__text {
    color: #76779a;
    margin-top: 20px;
  }

  &__heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0.01em;
    margin: 20px 0 40px;

    @media only screen and (max-width: 500px) {
      font-size: 36px;
    }
  }

  &__subheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0.01em;
  }

  &__button {
    background-color: #c9cdf8;
    height: 100%;
    border-radius: 6px;
    font-weight: bold;
  }

  &__input {
    border: none;
    padding: 10px 0;
    background-color: transparent;
    color: #fff;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #76779a;
    }
  }

  &__input-container {
    background: #3c3f84;
    border-radius: 10px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 6fr 3fr;
    height: 60px;
  }

  &__exist {
    border: 3px solid rgb(29, 201, 29);
  }

  &__noexist {
    border: 3px solid rgb(254, 0, 0);
  }

  &__result-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .check {
      color: rgb(29, 201, 29);
    }

    .close {
      color: rgb(254, 0, 0);
    }

    svg {
      height: 50%;
      width: 50%;
    }
  }

  &__platform-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  &__select {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border: 1px solid #c9cdf8;
    margin-left: 10px;
    padding: 0 10px;
    border-radius: 4px;
    background-color: #fff;

    .active {
      background-color: #c9cdf8;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
    }

    div {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
