.sellModal {
  width: 550px;
  border-radius: 8px;
  height: auto;
  background-color: #232449;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 33px;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;

  @media (max-width: 320px) {
    padding: 16px;
  }

  &--confirm {
    @media (max-width: 576px) {
      > div {
        flex-direction: column;
      }
    }
    label {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: #76779a;
    }

    button {
      background: #316bff;
      border-radius: 8px;

      font-family: 'Inter';
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      text-align: center;
      color: white;
      padding: 18px 54px;
    }

    p {
      text-align: left;
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      color: #76779a;

      strong {
        color: #316bff;
        font-weight: 700;
      }
    }

    svg {
      width: 16px;
      height: auto;
      margin-right: 8px;
    }
  }

  &__spinner {
    display: inline-block;
    width: 56px;
    height: 56px;
    border: 6px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    border-top-color: rgba(0, 0, 255, 0.6);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    margin-bottom: 1rem;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }

  &__hash,
  &__receipt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    h3 {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;

      text-align: center;
      letter-spacing: 0.01em;
    }

    button {
      width: 165px;
      padding: 18px 0;
      margin: 4rem 4px 0 4px;

      font-family: 'Inter';
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      text-align: center;
      border-radius: 8px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
  }
  h5 {
    font-weight: 500;
    font-size: 12px;
    color: #76779a;
    span {
      color: #fff;
    }
    line-height: 16px;
    text-align: center;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  &--auction {
    width: 100%;
    &--title {
      padding: 15px 0px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #76779a;
    }
  }
  &--fixed {
    margin-top: 79px;
    width: 100%;
    &--info {
      width: 100%;
      // min-height: 120px;
      background: #2c2d5a;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px;
      flex-direction: column;
      margin-bottom: 20px;

      &--infoItem {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 4px;

        @media (max-width: 320px) {
          font-size: 13px;
        }

        &--inputConainer {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          font-family: 'Inter';
          color: #83fff8;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
          span {
            margin-right: 8px;
          }
          &-input {
            color: rgb(255, 255, 255);
            padding: 13px;
            font-weight: 300;
            font-size: 16px;
            width: 150px;
            height: 42px;
            background-color: #15162d;
            outline: none;
            border: none;

            @media (max-width: 576px) {
              width: 90px;
            }

            @media (max-width: 320px) {
              width: 80px;
              height: 34px;
            }
          }
        }
      }
    }
  }
  &--tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .btn-group > .btn-group:not(:first-child),
    .btn-group > .btn:not(:first-child) {
      margin-left: 0.5rem;
    }
    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      border-radius: 8px;
    }
    .btn-group > .btn-group:not(:first-child) > .btn,
    .btn-group > .btn:nth-child(n + 3),
    .btn-group > :not(.btn-check) + .btn {
      border-radius: 8px;
    }
    .btn-check:active + .btn-primary,
    .btn-check:checked + .btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      border: 2px solid #ffffff;
      border-radius: 8px;
    }
    .btn.btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 600;
      background-color: transparent;
      border: 2px solid #4e507a;
      border-radius: 8px;
      color: #4e507a;
      width: 200px;
      height: 52px;
      font-size: 14px;
    }
    .btn-default:focus {
      outline: none;
      box-shadow: none;
    }
    .btn-group {
      width: 100%;
    }
    .btn-group-vertical {
      padding: 0.5rem 0.5rem;
    }
  }
}
