html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.nft-inventory {
  font-family: 'Inter';
  color: white;
  background: #15162d;
  margin: 32px 0px;

  @media (max-width: 576px) {
    margin: 0px;
  }

  &__spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;

    h3 {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
      text-align: center;
      margin-bottom: 0;

      color: white;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 18px 36px;
      gap: 8px;

      background: #316bff;
      border: none;
      border-radius: 8px;
      color: white;

      margin-top: 36px;
    }
  }

  // top
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: normal;
    }
  }

  &__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: white;
    margin: 16px 0px;
  }

  &__rarity-count {
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
      width: 100%;
      margin: auto;
      justify-content: space-between;
    }

    .item {
      &-wallet {
        display: flex;
        align-items: center;
        flex-direction: column;

        &__content {
          background: #232449;
          border-radius: 8px;
          padding: 17px 15px;
          display: flex;
          align-items: center;

          &:first-child {
            margin-bottom: 8px;
          }

          @media (max-width: 576px) {
            width: calc(50% - 4px);
            height: 48px;

            &:first-child {
              margin-bottom: 0;
            }
          }
        }

        @media (min-width: 1024px) {
          display: none;
        }

        @media (max-width: 1024px) {
          margin-right: 0;
          flex-grow: 1;
          align-items: flex-start;
        }

        @media (max-width: 576px) {
          width: 100%;
          margin-bottom: 7px;
          margin-left: 0;
          margin-right: 0;
          margin-top: 0;
          flex-direction: row;
          justify-content: space-between;
        }

        img {
          width: 28px;
          height: auto;
          margin-right: 5px;
        }

        span {
          font-weight: 800;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.02em;

          opacity: 0.9;
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #76779a;

          margin-bottom: 0;
        }

        svg {
          margin-left: 4px;
          width: 11px;
          height: 11px;
        }
      }

      &-rarity {
        @media (max-width: 576px) {
          width: 100%;
          justify-content: space-between;
          overflow-x: auto;
        }
      }
    }

    &__item {
      min-width: 156px;
      height: 140px;
      background: linear-gradient(
        180deg,
        #6ea0ea -212.61%,
        rgba(25, 26, 71, 0) 98.3%
      );
      img {
        width: 32px;
        height: 32px;
      }
      border: 1px solid #2e304a;
      border-radius: 8px;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 16px;

      @media (max-width: 768px) {
        min-width: 120px;
        height: 120px;
        margin-left: 0px;
        margin-right: 12px;
      }

      @media (max-width: 576px) {
        width: calc(30% - 5px);
        min-width: 110px;
        height: 110px;
      }

      p,
      h3 {
        margin-bottom: 0;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #76779a;
      }

      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 34px;
        letter-spacing: 0.01em;
        margin-top: 8px;
        opacity: 0.9;
        margin-bottom: 0;

        @media (max-width: 576px) {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }

  // division
  &__division {
    border-bottom: 1px solid #2e304a;
    margin: 28px 0px;
  }

  // option
  &__option {
    // showing xx items
    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      opacity: 0.39;
    }

    // sort dropdown
    .dropdown-toggle {
      width: 190px;
      background: #232449;
      border: 1px solid #2e304a;
      border-radius: 8px;
      color: white;
    }
    .dropdown-menu {
      background: #2c2d5a;
      border: 1px solid #2e304a;
      border-radius: 8px;
    }
    .dropdown-item {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      opacity: 0.8;
      height: 40px;
    }
    .nav-item .dropdown {
      background: #232449;
      border: 1px solid #2e304a;
      border-radius: 8px;
    }
    .btn.btn-primary {
      background: #15162d;
      border: 1px solid #2e304a;
      border-radius: 8px;
      width: 160px;
      height: 48px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      opacity: 0.6;
    }
  }

  //content
  &__content {
    margin-bottom: 3rem;

    @media (max-width: 576px) {
      padding: 0 12px;
    }

    &__filter {
      > button {
        width: 190px;
        height: 50px;
        background: #15162d;

        border: 1px solid #2e304a;
        border-radius: 8px;

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        opacity: 0.6;
      }
    }
  }

  &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 16px;
    min-height: 288px;

    @media (max-width: 576px) {
      justify-content: space-between;
    }
  }

  // nft item
  &__item {
    cursor: pointer;
    background: #232449;
    border: 2px solid transparent;
    border-radius: 8px;
    margin: 8px 0px;
    position: relative;

    &:hover {
      .nft-inventory__item-content {
        background: linear-gradient(
          180deg,
          rgba(25, 26, 71, 0) 12.61%,
          #6eeae2 411.3%
        );
      }

      border: 2px solid #83fff8;
      box-shadow: 0px 2px 12px rgba(110, 234, 212, 0.6);
      border-radius: 8px;
    }

    &-loading {
      position: absolute;
      inset: 0;
      display: grid;
      place-items: center;
      z-index: 100;
      background-color: black;
      opacity: 0.7;
    }

    &-image {
      position: relative;
      &-rarity {
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 3px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #ffffff;
        padding: 0px 10px;
        z-index: 11;
      }
      &-frame {
        z-index: 11;
        position: relative;
      }

      &-revelation-frame {
        z-index: 11;
        position: absolute;
        inset: 0;
      }

      &-avatar {
        z-index: 1;
        position: absolute;
        object-fit: cover;
        height: 100%;
        inset: 0;
      }

      img {
        width: 100%;
        border-radius: 8px 8px 0px 0px;
      }
    }

    &-badge {
      position: absolute;
      top: 11px;
      right: 11px;

      svg {
        width: 26px;
        height: 26px;
      }
    }

    &-attr {
      position: absolute;
      left: 11px;
      bottom: 11px;

      @media (max-width: 576px) {
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      }

      img {
        width: 26px;
        height: 26px;
        border: 1px solid #76779a;
        border-radius: 2.66345px;

        margin-right: 4px;
        background: linear-gradient(90deg, #463774 51.57%, #232449 73.77%);
      }
    }

    &-content {
      min-height: 90px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        margin-bottom: 0;
        max-width: 175px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-hero {
        &-knight {
          background: #ad2336;
          border-radius: 3px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;

          padding: 0 4px;
          margin-right: 6px;
        }
      }

      .item-id {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #76779a;

        margin-bottom: 0;
      }

      .item-price {
        &-token {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          margin-right: 6px;
          margin-left: 4px;

          color: #83fff8;
        }

        &-gems {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          margin-right: 6px;
          margin-left: 4px;

          color: #fff;
        }

        &-dollar {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;

          color: #36ac5d;
        }
      }
    }
  }
}
