@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800';
@import 'https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800';
@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  background-color: #15162d;
  position: relative;
  padding-bottom: 200px;
  min-height: 100vh;

  @media (max-width: 576px) {
    padding-bottom: 300px;
  }
}
* {
  // font-family: "Roboto";
}

iframe {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('./pages/VStaking/vstaking.css');

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.modal {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000060;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &_content {
    background-color: #232449;
    border-radius: 2rem;
    max-width: 40rem;
    color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_button {
      margin-top: 2rem;
      background-color: #83fff8;
      padding: 1rem 3rem;
      border-radius: 1rem;
    }
  }
}
