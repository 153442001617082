html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.nft-detail {
  font-family: 'Inter';
  color: white;
  width: 100%;
  padding-top: 44px;
  padding-bottom: 44px;
  margin: auto;
  @media (max-width: 576px) {
    padding: 20px 18px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }

  &__spinner {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);

    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 15px;
    cursor: pointer;
    user-select: none;
    transform: translateX(-16px);

    img {
      width: 8px;
      height: auto;
      margin-right: 6px;
    }

    span {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      font-weight: 600;
    }

    &-wrapper {
      padding: 18px 35px;
      background: #232449;
      border-radius: 8px;
    }

    &-list {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        width: 18px;
        height: 18px;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #76779a;
        margin: 0;
      }

      strong {
        font-size: 12px;
        color: white;
      }
    }

    @media (max-width: 1024px) {
      transform: translateX(0);
    }
  }

  &__section {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__avatar {
    margin-right: 10%;
    margin-top: 80px;
    position: relative;

    img {
      width: 318px;
      max-width: initial;
      height: 318px;
      object-fit: cover;
      border-radius: 8px;
    }

    @media (max-width: 1024px) {
      margin-top: 24px;
      margin-right: 18px;

      img {
        width: 248px;
        height: 248px;
        border-width: 5px;
      }
    }

    @media (max-width: 576px) {
      margin-top: 32px;
      margin-left: 8px;
      margin-right: 8px;

      img {
        max-width: 100%;
        width: 100%;
        height: auto !important;
      }
    }
  }

  &__metadata {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn.btn-primary {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        border-radius: 8px;
        background-color: #83fff8;
        color: #000;
        font-size: 14px;
        width: 132px;
        height: 56px;

        &.danger {
          padding: 18px 36px;
          background: #c51051;
          width: auto;
          border: none;
          color: white;
        }
      }

      &.mobile {
        justify-content: space-between;

        @media (max-width: 576px) {
          display: flex !important;
          justify-content: space-around;
          background: #06081b;
          padding: 15px 10px 20px 10px;

          button {
            margin-left: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 18px 24px;
            gap: 8px;

            background: #83fff8;
            border-radius: 8px;
            border-color: transparent;

            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-align: center;

            color: #232449;
            margin: 0;
          }
        }

        @media (min-width: 576px) {
          display: none;
        }
      }

      &.tablet {
        background: #06081b;
        border-radius: 8px;
        padding: 31px;
        margin: auto;
        margin-bottom: 26px;
        .text {
          &-owner {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.08em;
            text-transform: uppercase;

            color: #b7f6ff;

            &__name {
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: white;

              margin-bottom: 0;
            }

            &__address {
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 0px !important;
            }
          }
        }

        @media (max-width: 1024px) and (min-width: 576px) {
          display: flex !important;
        }

        @media (min-width: 1024px) {
          display: none;
        }
      }

      @media (max-width: 1024px) {
        display: none !important;
      }
    }

    &-owner {
      &.mobile {
        background: #2c2d5a;
        border-radius: 0px 0px 16px 16px;
        padding: 16px 24px;
        margin-top: 30px;
        transform: translateY(-3rem);

        .text-owner {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #b7f6ff;
          margin-bottom: 6px;

          &__address {
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 0px !important;
          }
        }

        p {
          font-size: 12px;
        }

        @media (min-width: 576px) {
         
          display: none;
        }
      }
    }

    &-list {
      flex-grow: 1;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #76779a;

        margin-bottom: -6px;
      }

      strong {
        font-size: 12px;
        color: white;
      }
    }

    &-price {
      margin-right: 24px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      span {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: right;
        letter-spacing: 0.01em;

        color: #83fff8;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: right;

        color: #76779a;

        margin: 0;
      }

      &__wrapper {
        display: flex;

        @media (max-width: 576px) {
          width: 100%;
          margin-left: 20%;
          justify-content: space-between;
        }
      }

      .price-dollar {
        margin-top: 6px;
        font-size: 16px;
        line-height: 16px;
        color: #36ac5d;
      }
    }

    .text-owner {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #b7f6ff;
      margin-bottom: 6px;

      &__name {
        color: white;
        margin-bottom: 0;
      }

      &__address {
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 0px !important;
      }
    }

    &-action {
      display: flex;
      align-items: flex-start;

      @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          text-align: right;
          margin-bottom: 0;
          margin-bottom: 0;
          color: #76779a;
          font-size: 10px;
        }
      }

      .price-token {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.01em;
        color: #83fff8;
        margin-bottom: 0;
      }

      .price-dollar {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #76779a;
        text-align: right;
      }

      button {
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 18px 36px;
        gap: 8px;

        background: #83fff8;
        border-radius: 8px;
        border-color: transparent;

        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        color: #232449;
      }
    }
  }

  &__content {
    padding: 38px 42px;
    background: #232449;
    border: 1px solid #2d2f5e;
    border-radius: 8px;

    margin-top: 24px;

    @media (max-width: 576px) {
      padding: 0 12px;
      // background: linear-gradient(
      //   180deg,
      //   rgba(110, 234, 226, 0.3) 0%,
      //   rgba(35, 36, 73, 1) 10%,
      //   rgba(35, 36, 73, 1) 90%,
      //   rgba(35, 36, 73, 1) 100%
      // );
      border-radius: 16px 16px 0 0 !important;
    }

    @media (max-width: 1024px) {
      padding: 25px 22px 32px 22px;

      // background: linear-gradient(
      //   180deg,
      //   rgba(110, 234, 226, 0.3) 0%,
      //   rgba(35, 36, 73, 1) 10%,
      //   rgba(35, 36, 73, 1) 90%,
      //   rgba(35, 36, 73, 1) 100%
      // );
      border-radius: 16px;
    }

    &.mobile-top {
      padding: 0;
      background: none;
      border: none;

      @media (min-width: 576px) {
        display: none;
      }
    }

    &__wrapper {
      @media (max-width: 576px) {
        display: none !important;
      }
    }

    .item {
      &-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 34px;
        letter-spacing: 0.01em;
        margin-bottom: 0;
      }

      &-id {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #76779a;
        margin-bottom: 0;

        @media (max-width: 576px) {
          margin-top: 10px;
        }
      }

      &-description-text,
      &-ability {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #b7f6ff;

        margin-top: 24px;
        margin-bottom: 8px;
      }

      &-description {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #d3d3ed;
        margin-bottom: 16px;
      }

      &-ability {
        &-type {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0;
        }

        &-value {
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          margin-bottom: 0;

          color: #76779a;
        }
      }
    }

    .hero {
      &__knight {
        padding: 7px 12px;
        background: #ad2336;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-right: 7px;
        margin-top: 16px;
      }
    }

    .rarity {
      &__legendary {
        padding: 7px 12px;
        background: #ff8000;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-right: 7px;
        margin-top: 16px;
      }
    }

    .nft {
      &__beta {
        position: relative;
        display: flex;
        align-items: center;
        padding: 7px 12px;
        background: transparent;
        border: 1px solid #6b649c;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-right: 7px;
        margin-top: 16px;

        svg {
          margin-left: 4px;
          cursor: pointer;
        }

        > span {
          position: absolute;
          top: 2rem;
          transform: translateX(calc(100% - 6rem));
          width: 200px;
          z-index: 1000;

          background: #2c2d5a;
          border: 1px solid #454981;
          border-radius: 8px;
          font-size: 10px;
          line-height: 12px;
          color: #76779a;
          padding: 14px 8px;

          @media (max-width: 576px) {
            transform: translateX(calc(100% - 10rem));
          }
        }
      }
    }
  }

  &__ability {
    @media (max-width: 576px) {
      margin-bottom: 36px;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-item {
      background: #2c2d5a;
      border-radius: 8px;
      padding: 12px 11px;
      width: 48%;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 8px;
      }

      &__image {
        background: linear-gradient(
          90deg,
          rgba(161, 150, 235, 0.168) 51.57%,
          rgba(161, 150, 235, 0.06) 73.77%
        );
        border-radius: 5.75px;
        width: 48px;
        min-width: 48px;
        height: 48px;
        margin-right: 10px;
        padding: 2px;

        img {
          width: 100%;
          height: auto;
        }
      }

      &__text {
      }
    }
  }
}
