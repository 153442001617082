.mobile-mcrt-reward-table {
  border-radius: 10px;
  border: 1px solid #222a5c;

  &--header {
    display: flex;
    width: 100%;
    background: #182252;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &-cell {
      text-align: left;
      padding: 16px;
      white-space: pre;
      font-size: 16px;
      border: 1px solid #222a5c;

      font-weight: 500;
      &__duration {
        width: 30%;
        border-top-left-radius: 10px;
      }
      &__bonus {
        width: 30%;
      }
      &__staked {
        width: 30%;
      }
      &__action {
        width: 10%;
        border-top-right-radius: 10px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &--body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &-row {
      width: 100%;
      background: transparent;
      &:last-child {
        .mobile-mcrt-reward-table--body-row-origin-cell__duration {
          border-bottom-left-radius: 10px;
        }
        .mobile-mcrt-reward-table--body-row-origin-cell__action {
          border-bottom-right-radius: 10px;
        }
      }
      &-origin {
        display: flex;
        width: 100%;
        &-cell {
          text-align: left;
          padding: 16px;
          white-space: pre;
          font-size: 14px;
          border: 1px solid #222a5c;
          font-weight: 500;
          &__duration {
            width: 30%;
          }
          &__bonus {
            width: 30%;
          }
          &__staked {
            width: 30%;
          }
          &__action {
            width: 10%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &-icon {
              border-radius: 50%;
              height: 24px;
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.7);
              color: #131a40;
            }
          }
        }
      }
      &-expanded {
        padding: 16px;
        &-label {
          opacity: 0.5;
          width: 120px;
          display: inline-block;
        }
        &-value {
          opacity: 0.8;
        }
      }
    }

    &-row:nth-child(even) {
      background: #111737;
    }
  }
}
