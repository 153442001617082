.collection {
  display: flex;
  justify-content: center;

  &-container {
    display: flex;
    align-items: center;
    border: 1px solid #2e304a;
    border-radius: 8px;
    padding: 5px 15px;
    max-width: 200px;
    min-height: 60px;

    &-label {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
      font-weight: bold;
    }

    &-select {
      background-color: transparent;
      color: #fff;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
