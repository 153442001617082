.merchandise-container {
  padding: 2rem;
  background-color: transparent;
  min-height: 100vh;
}

.merchandise-header {
  text-align: left;
  margin-bottom: 2rem;
  padding-left: 1rem;
}

.merchandise-title {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.merchandise-subtitle {
  color: #a8a8c0;
  font-size: 1.25rem;
  font-weight: 400;
}

.merchandise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 0 1rem;
}

.merchandise-item {
  background-color: #1a1b36;
  border-radius: 12px;
  overflow: hidden;
  width: 280px;
  transition: transform 0.2s;
  cursor: pointer;
}

.merchandise-item:hover {
  transform: translateY(-5px);
}

.merchandise-card {
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  position: relative;
}

.card-image-container {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.merchandise-image-container {
  height: 220px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #232449;
}

.merchandise-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.merchandise-card .card-body {
  padding: 1rem;
  background-color: #ffffff;
  color: #171730;
}

.merchandise-card .card-title {
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #171730;
  display: none;
}

.product-type {
  font-size: 0.85rem;
  color: #a8a8c0;
  margin-bottom: 0.5rem;
}

.price-container {
  display: flex;
  flex-direction: column;
}

.product-price {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4e6fff;
}

.mcrt-price {
  font-size: 0.85rem;
  color: #a8a8c0;
}

.view-details-btn {
  width: 100%;
  background-color: #4e6fff;
  border: none;
  padding: 0.75rem;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 1rem;
}

/* Loading state */
.merchandise-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: #ffffff;
}

.merchandise-loading .spinner-border {
  color: #4e6fff;
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
}

/* Error state */
.merchandise-error {
  text-align: center;
  padding: 2rem;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 8px;
  color: #ffffff;
}

/* Empty state */
.merchandise-empty {
  text-align: center;
  padding: 3rem;
  color: #a8a8c0;
}

/* Modal styling */
.merchandise-modal .modal-content {
  background-color: #171730;
  color: #ffffff;
  border-radius: 8px;
  border: none;
}

.merchandise-modal .modal-header {
  border-bottom: 1px solid #252542;
  padding: 1.25rem 1.5rem;
}

.merchandise-modal .modal-title {
  font-weight: 600;
  color: #ffffff;
}

.merchandise-modal .close {
  color: #ffffff;
}

.product-carousel {
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
}

.product-carousel .carousel-item img {
  height: 400px;
  object-fit: contain;
}

.product-details {
  padding: 0 1rem;
}

.modal-price-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.product-modal-price {
  font-size: 1.75rem;
  font-weight: 600;
  color: #4e6fff;
  margin-bottom: 0.25rem;
}

.modal-mcrt-price {
  font-size: 1rem;
  color: #a8a8c0;
}

.product-description {
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: #a8a8c0;
}

.product-options {
  margin-bottom: 1.5rem;
}

.product-options h5 {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  color: #ffffff;
}

.option-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.option-button {
  background-color: transparent;
  border: 1px solid #4e6fff;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.option-button.btn-primary {
  background-color: #4e6fff;
  border: none;
}

.buy-button {
  width: 100%;
  padding: 0.75rem;
  font-weight: 500;
  font-size: 1.1rem;
  background-color: #4e6fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.buy-button:hover {
  background-color: #3a5aff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .merchandise-container {
    padding: 1rem;
  }
  
  .merchandise-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 1rem;
  }
  
  .card-image-container {
    height: 180px;
  }
  
  .product-carousel .carousel-item img {
    height: 300px;
  }
  
  .product-details {
    padding: 1rem 0;
    margin-top: 1.5rem;
  }
}

.modal-90w {
  max-width: 90%;
}

/* Corner decoration like in the gem cards */
.merchandise-card:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: 15px;
  height: 15px;
  border-top: 2px solid #ff6b00;
  border-left: 2px solid #ff6b00;
}

.merchandise-card:after {
  content: '';
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #ff6b00;
  border-right: 2px solid #ff6b00;
}

/* Add rarity badge like in the NFT cards */
.merchandise-card .rarity-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.2rem 0.6rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  background-color: #4e6fff;
}

.nft-inventory__item-content {
  padding: 16px;
} 