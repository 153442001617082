@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.main {
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 49px;
  ::-webkit-scrollbar {
    display: none;
  }
  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
  .nav-tabs {
    border-bottom: 1px solid #282949;
  }
  .nav-link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #5c5f97;
  }
  .nav-tabs .nav-link {
    background-color: transparent !important;
    border-width: 0px;
    color: #5c5f97;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: white;
    background-color: transparent;
    border-width: 0px;
    border-bottom: 2px solid #83fff8;
  }
  &__container {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    width: 100%;

    &--spinner {
      position: absolute;

      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;

      @keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }
    }

    .badge {
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }

    .btn-group > .btn-group:not(:first-child),
    .btn-group > .btn:not(:first-child) {
      margin-left: 0.5rem !important;
    }
    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      border-radius: 8px;
    }
    .btn-group > .btn-group:not(:first-child) > .btn,
    .btn-group > .btn:nth-child(n + 3),
    .btn-group > :not(.btn-check) + .btn {
      border-radius: 8px;
    }
    .btn-check:active + .btn-primary,
    .btn-check:checked + .btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #316bff;
      border-color: #316bff;
    }
    .btn {
      border-radius: 8px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      color: #ffffff;
    }
    .btn-primary {
      color: #fff;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      background-color: #4e507a;
      border-color: #4e507a;
    }
    .btn-default:focus {
      outline: none;
      box-shadow: none;
    }
    .btn-group,
    .btn-group-vertical {
      padding: 0.5rem 0.5rem;
    }
    &--filter {
      max-width: 300px;
      max-height: 500px;
      width: 100%;
      background: #232449;
      border: 1px solid #2e304a;
      border-radius: 8px;
      height: auto;

      &-container {
        display: flex;

        &-btn {
          background-color: #4e507b;
          color: white;
          border: none;
          height: 32px;
          font-size: 20px;
        }
      }

      @media (max-width: 576px) {
        max-width: 100%;
        width: 100%;
      }
      &--divider {
        width: 100%;
        border: 1px solid #2e304a;
      }
      &--titleContainer {
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
        width: 100%;
        font-family: 'Inter';
        font-style: normal;
        &--title {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #ffffff;
        }
        &--reset {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #83fff8;
          opacity: 0.9;
          cursor: pointer;
        }
      }
      &--filterTypeContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        ::-webkit-scrollbar {
          display: none;
        }

        ul {
          padding: 0rem;
          width: 280px;
          max-height: 200px;
          margin-bottom: 10px;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
        }
        .list-group-item {
          display: flex;
          padding: 0.5rem 1rem;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          text-decoration: none;
          background-color: #191a39;
          border: 1px solid rgba(0, 0, 0, 0.125);
          opacity: 0.7;
          align-items: center;
          width: 100%;
        }
        li {
          width: 100%;
          img {
            margin-right: 1rem;
          }
        }
        &--filterScrolView {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &--filterTypeHeader {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          &--filterTypeHeaderIcon {
            display: flex;
            flex-direction: row;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #ffffff;
          }
        }
      }
    }
    &--items {
      &--empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1 {
          font-size: 28px;
        }
      }
    }
    &--lands {
      &--empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1 {
          font-size: 28px;
        }
      }

      h1 {
        font-size: 28px;
      }
    }
    &--itemListContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: calc(100% - 300px);
      width: 100%;
      @media (max-width: 900px) {
        width: 100%;
        max-width: 100%;
      }
      &--itemListFilter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 1rem;
        @media (max-width: 576px) {
          width: 100%;
          padding: 0.5rem 1rem 0;
        }
        .dropdown-toggle {
          width: 190px;
          background: #232449;
          border: 1px solid #2e304a;
          border-radius: 8px;
          color: white;
        }
        .dropdown-menu {
          background: #2c2d5a;
          border: 1px solid #2e304a;
          border-radius: 8px;
        }
        .dropdown-item {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          opacity: 0.8;
          height: 40px;
        }
        .nav-item .dropdown {
          background: #232449;
          border: 1px solid #2e304a;
          border-radius: 8px;
        }
        .btn.btn-primary {
          background: #15162d;
          border: 1px solid #2e304a;
          border-radius: 8px;
          // width: 128px;
          height: 48px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          opacity: 0.6;
        }
        h5 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 129% */

          display: flex;
          align-items: center;

          /* White */

          color: #ffffff;

          opacity: 0.39;
        }
      }
      &--itemListView {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 20px;
        // height: 500px;
        overflow: auto;

        &--wrapper {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        // margin-top: 16px;
        .infinite-scroll-component {
          display: flex;
          width: 100%;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: center;

          &__outerdiv {
            width: 100%;
          }

          @media (max-width: 576px) {
            width: 100%;
            // max-width: 10
            justify-content: center;
            column-gap: 10px;
            margin-top: 0px;
          }
        }
        &--itemView {
          width: 194px;
          border-radius: 8px 8px 0px 0px;
          margin-right: 1rem;

          @media (max-width: 576px) {
            margin-right: 0rem;
            width: 169px;
          }
        }
        @media (max-width: 576px) {
          width: 100%;
          // max-width: 10
          justify-content: center;
          margin-top: 0px;
          padding: 0.5rem 0.75rem;
          column-gap: 10px;
        }
      }
    }
  }
  &__mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    &--filterModal {
      width: 100%;
      border-radius: 0px;
      height: 100vh;
      background-color: #232449;
      display: flex;
      justify-content: center;
      .badge {
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
      }

      .btn-group > .btn-group:not(:first-child),
      .btn-group > .btn:not(:first-child) {
        margin-left: 0.5rem;
      }
      .btn-group > .btn-group:not(:last-child) > .btn,
      .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
        border-radius: 8px;
      }
      .btn-group > .btn-group:not(:first-child) > .btn,
      .btn-group > .btn:nth-child(n + 3),
      .btn-group > :not(.btn-check) + .btn {
        border-radius: 8px;
      }
      .btn-check:active + .btn-primary,
      .btn-check:checked + .btn-primary,
      .btn-primary.active,
      .btn-primary:active,
      .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #316bff;
        border-color: #316bff;
      }
      .btn {
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #ffffff;
      }
      .btn-primary {
        color: #fff;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        background-color: #4e507a;
        border-color: #4e507a;
      }
      .btn-default:focus {
        outline: none;
        box-shadow: none;
      }
      .btn-group,
      .btn-group-vertical {
        padding: 0.5rem 0.5rem;
      }
      &--filter {
        max-width: 300px;
        max-height: 500px;
        width: 100%;
        background: #232449;
        border: 1px solid #2e304a;
        border-radius: 8px;
        height: auto;
        @media (max-width: 576px) {
          max-width: 100%;
          width: 100%;
        }
        &--divider {
          width: 100%;
          border: 1px solid #2e304a;
        }
        &--titleContainer {
          display: flex;
          align-items: center;
          padding: 1rem;
          justify-content: space-between;
          width: 100%;
          font-family: 'Inter';
          font-style: normal;
          &--title {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: #ffffff;
          }
          &--reset {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #83fff8;
            opacity: 0.9;
            cursor: pointer;
          }
        }
        &--filterTypeContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          justify-content: center;
          ::-webkit-scrollbar {
            display: none;
          }

          ul {
            padding: 0rem;
            width: 50vh;
            max-height: 200px;
            margin-bottom: 10px;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
          }
          .list-group-item {
            display: flex;
            padding: 0.5rem 2rem;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            text-decoration: none;
            background-color: #191a39;
            border: 1px solid rgba(0, 0, 0, 0.125);
            opacity: 0.7;
            align-items: center;
            width: 100%;
          }
          li {
            width: 100%;
            img {
              margin-right: 1rem;
            }
          }
          &--filterTypeHeader {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            &--filterTypeHeaderIcon {
              display: flex;
              flex-direction: row;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              text-transform: uppercase;
              color: #ffffff;
            }
          }
        }
      }
    }

    &--header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0.75rem;

      @media (max-width: 576px) {
        padding: 0.4rem 0.75rem;
      }

      .dropdown-toggle {
        width: 190px;
        background: #232449;
        border: 1px solid #2e304a;
        border-radius: 8px;
        color: white;
      }
      .dropdown-menu {
        background: #2c2d5a;
        border: 1px solid #2e304a;
        border-radius: 8px;
      }
      .dropdown-item {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        opacity: 0.8;
        height: 40px;
      }
      .nav-item .dropdown {
        background: #232449;
        border: 1px solid #2e304a;
        border-radius: 8px;
      }
      .btn.btn-primary {
        background: #15162d;
        border: 1px solid #2e304a;
        border-radius: 8px;
        width: 128px;
        height: 48px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;

        opacity: 0.6;
      }
    }
  }
}
