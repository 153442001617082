.top-hundred {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 500px) {
    padding: 0 1rem;
  }

  &__banner {
    border-radius: 20px;
    height: 240px;
    width: 100%;
    max-width: 1200px;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-image: url('../../assets/top-banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin: 32px 0;

    @media only screen and (max-width: 1200px) {
      background-position: right;
    }

    @media only screen and (max-width: 900px) {
      padding: 0 1rem;
      background-image: url('../../assets/top-banner-mobile.png');
      margin: 14px 0;
    }
  }

  &__text {
    color: white;
    max-width: 420px;

    h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 43px;
      letter-spacing: 0.01em;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.7;
    }
  }

  .load-next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    cursor: pointer;
    color: #83fff8;

    p {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
    }
  }

  &__list {
    background-color: #232449;
    padding: 1rem 2rem;
    border-radius: 20px;
    width: 100%;
    max-width: 1200px;
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    .label {
      color: #6c7e99;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    p {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    &-knight {
      color: white;
      display: grid;
      grid-template-columns: 1fr 12fr 4fr 4fr 2fr;
      gap: 2rem;
      padding: 20px 0;
      min-width: fit-content;
      border-bottom: 1px solid #5a5f9c;

      @media only screen and (max-width: 1140px) {
        grid-template-columns: 1fr 6fr 4fr 4fr 2fr;
      }

      @media only screen and (max-width: 920px) {
        grid-template-columns: 1fr 4fr 2fr 2fr 1fr;
      }

      &-arrow {
        display: flex;
        align-items: flex-start;
      }

      .up {
        color: #abfcb3;
      }

      .down {
        color: #fcabab;
      }

      &-rank {
        display: flex;
        flex-direction: column;
      }

      &-wallet {
        display: flex;
        flex-direction: column;

        &-container {
          display: flex;
          align-items: center;

          @media only screen and (max-width: 1140px) {
            p {
              width: 120px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &-amount {
      }

      &-amount-MCRT {
        &-container {
          display: flex;

          img {
            height: 20px;
            width: 20px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.copy-overlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.copy-container {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 14px 30px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
