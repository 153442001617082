.help {
  margin-top: 13px;
  width: 100%;

  &-content {
    padding-top: 13px;
    padding-bottom: 13px;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
      text-decoration-color: #d34d4d;
    }

    & .icon-container {
      width: fit-content;
    }

    p {
      width: fit-content;
      margin: 0;
      padding: 0;
      color: #d34d4d;
      font-size: 18px;
      font-weight: 500;
      transition: 0.3 ease-out all;
    }
  }
}

.center {
  display: grid;
  place-items: center;
}
