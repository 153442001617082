.staking-help-modal__container {
  position: fixed;
  inset: 0;
  background-color: rgba(10, 9, 23, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  z-index: 999;
  height: 100dvh;
  width: 100%;
}

.staking-help-modal {
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(112, 186, 195, 0.5) rgba(255, 255, 255, 0.15); /* For Firefox */
  position: relative;
  max-width: 700px;
  margin: 0 16px;
  height: 672px;
  max-height: 90%;
  border-radius: 20px;
  border: 1px solid #41476a;
  background: rgba(8, 5, 24);
  box-shadow: 0px 10px 70px 0px rgba(71, 84, 181, 0.5);
  color: white;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.15);
    margin-block: 20px;
    scroll-margin-inline: 50px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: rgba(112, 186, 195, 0.5);
  }

  & .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

  &__content {
    padding: 36px 60px 30px;

    &--footer {
      padding-top: 20px;
      & h4 {
        font-family: Montserrat;
        font-size: 22px;

        font-weight: 900;
      }

      & p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;

        & a {
          color: #98fff9;
          text-decoration-line: underline !important;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 45px 20px 30px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    padding-bottom: 45px;

    &--title {
      & h2 {
        max-width: 90%;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 800;
      }

      & p {
        color: #fff;
        font-family: Futura PT;
        font-size: 16px;

        font-weight: 500;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      gap: 0;

      &--title {
        & h2 {
          font-size: 22px;

          max-width: 100%;
        }
      }
    }
  }

  &__download {
    flex-shrink: 0;
    cursor: pointer;
    color: #98fff9;
    display: flex;
    align-items: center;

    & span {
      font-family: Futura PT;
      font-size: 16px;
      font-weight: 450;
      margin-right: 10px;
    }
  }

  &__steps {
    font-family: Futura PT;

    & ol {
      margin: 0;
      padding: 0;

      & li {
        padding-bottom: 35px;

        & h6 {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 700;
        }

        & h5 {
          font-size: 20px;
          font-weight: 700;
        }

        & p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
        }

        & a {
          color: #98fff9;
          text-decoration: underline !important;
        }

        & .change-url {
          max-width: 440px;
          padding-bottom: 10px;
        }

        & .staking-badge {
          &__icon {
            flex-shrink: 0;
            font-size: 16px;
          }
          display: flex;
          padding: 6px 12px;
          align-items: center;
          gap: 8px;
          border-radius: 20px;
          max-width: 520px;
          background: rgba(255, 255, 255, 0.1);

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
