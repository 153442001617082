// header
.bg-header {
  background-color: #06071b;

  &-sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .navbar-item {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;

      @media only screen and (max-width: 1500px) {
        display: none;
      }
    }
  }

  .header-navbar-toggle-container {
    position: relative;

    @media (min-width: 1200px) {
      display: none;
    }

    .header-navbar-toggle {
      background-color: #232449;
      border-radius: 10px;
      height: 56px;
      width: 56px;
      border: none;

      .navbar-toggler-icon {
        display: none;
      }
    }

    .lines {
      width: 16px;
      height: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        height: 2px;
        width: 100%;
        display: block;
        background-color: #fff;
        border-radius: 2px;
      }
    }

    .blue-circle {
      height: 24px;
      width: 24px;
      border-radius: 20px;
      background-color: #83fff8;
      border: 4px solid #06071b;
      position: absolute;
      top: -6px;
      left: -12px;
    }
  }

  // logo section
  .header-logo {
    height: 40px;
    img {
      width: auto;
      height: 30px;
    }
  }

  .header-logo__normal {
    display: block;
    width: 100%;
  }

  .header-logo__mobile {
    display: none;
    width: 40px;
  }

  // menu section
  .header-menu {
    @media (min-width: 1024px) {
      &:first-child {
        margin-left: 44px;
      }
    }

    h6 {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      color: #76779a;
      margin-bottom: 0;
    }

    svg {
      color: #76779a;
    }

    &:hover,
    &.active {
      h6 {
        color: white;
      }

      svg {
        color: white;
      }
    }
    &:focus {
      h6 {
        color: white;
      }

      svg {
        color: white;
      }
    }
  }
}

// connect wallet btn
.connect-wallet-btn {
  background: rgba(44, 45, 90, 0.2);
  border: 1px solid #2c2d5a;
  border-radius: 8px;
  padding: 4px 4px 4px 7px;
  padding-left: 7px !important;
  background: rgba(44, 45, 90, 0.2);

  &.connected {
    padding: 0 !important;
    padding-left: 0 !important;
    border: none !important;
    background: none !important;
  }

  &.mint-page {
    padding: 4px 4px 4px 14px;
    padding-left: 14px !important;

    img {
      width: 16px !important;
      height: 16px !important;
      margin-right: 5px !important;
      border: none !important;
    }

    h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  img {
    width: 28px;
    height: 28px;
    margin-right: 7px;
    border: 4px solid #2c2d5a;
    border-radius: 50%;
  }

  h6 {
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 700;
    margin-bottom: 0;
  }

  button {
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  p {
    font-family: 'Inter';
    font-size: 12px;
    color: #76779a;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  &--mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .mobile-staking-title {
    display: block !important;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #030f36;
    z-index: 1;
    border-radius: 22px;
  }
  .connect-wallet-btn {
    &--desktop {
      display: none !important;
    }

    &--mobile {
      display: flex !important;
      // display: flex;
      flex-direction: row !important;
      margin-right: 10px;

      button {
        font-size: 12px !important;
        height: 40px;
        padding: 4px 6px !important;
      }
    }
  }

  .header-navbar-container:not(.show) {
    display: none !important;
  }
}

// mobile
@media only screen and (max-width: 576px) {
  .header-logo__normal {
    display: none !important;
  }

  .header-logo__mobile {
    display: block !important;
  }
}
