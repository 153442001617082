@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

$itemListBp: 1500px;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.nftmint {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 3rem;
  color: white;
  max-width: 1200px;
  min-height: fit-content;
  justify-content: center;
  padding: 0 2rem;

  @media only screen and (max-width: 800px) {
    margin-top: 3rem;
  }

  @media only screen and (max-width: 576px) {
    padding: 0 1rem;
    margin-top: 0;
    margin-top: 1rem;
    height: auto;
  }

  &__list {
    li {
      list-style-type: square;
    }
  }

  &__grouping {
    &__col {
      display: flex;
      flex-direction: column;
    }

    &__row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      h5 {
        margin: 0;
        margin-right: 4px;
      }
    }
  }

  &__question {
    &-modal {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      border-radius: 20px;
      background-color: #232449;
      padding: 20px 10px;

      &__icon {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
    }
  }

  &__banner-container {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(20, 20, 20, 0.6)),
      // url('https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp');
      url('https://res.cloudinary.com/dfzcr2ch4/image/upload/v1731663723/twitter_banner3__ficf19.webp');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: auto;
    border-radius: 20px;
    max-width: 1200px;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 40px;
    inset: 0;

    &.--blazy {
      background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(20, 20, 20, 0.6)),
        url('https://res.cloudinary.com/dfzcr2ch4/image/upload/v1732089637/dogewarriornft_koyb8l.webp');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      height: unset;
      padding: 30px;
    }
  }


  &__available-container {
    background-color: #06071b;
    padding: 20px 30px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    @media (max-width: 576px) {
      width: 100%;
      max-width: unset;
      margin-top: 10px;
    }

    .icon {
      margin-right: 10px;
    }

    &__text {
      font-size: 40px;
      color: white !important;
      display: flex;
      align-items: center;
    }
  }

  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 43px;
    letter-spacing: 0.01em;
    color: #ffffff;

    @media (max-width: 576px) {
      text-align: left;
      font-size: 32px;
      line-height: 34px;
      margin-top: 0px;
    }
  }

  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: rgb(233, 233, 233);

    @media (max-width: 576px) {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
    }
  }

  h4 {
    margin-top: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    border-radius: 8px;
    text-align: center;
    padding: 10px 0;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(233, 233, 233);
    padding: 1rem 2rem;
    color: rgb(233, 233, 233);
    cursor: pointer;
    font-size: 18px;
    background: linear-gradient(to left,
        transparent 50%,
        rgb(233, 233, 233) 50%) right;
    background-position: right;
    background-size: 200% 100%;
    transition: 0.5s ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;

    &:hover {
      color: black;
      background-position: left;
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.98);
    }

    @media (max-width: 800px) {
      margin-top: 30px;
    }

    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
      margin-top: 20px;
    }
  }

  &__button_mobile {
    width: 100%;
    height: 98px;
    padding: 1.5rem;
    position: fixed;
    background: #06081b;
    left: 0px;
    z-index: 10;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--info {
      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #ffffff;
      }

      h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #83fff8;
        opacity: 0.8;
      }
    }

    button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #2a2d5b;
      background: #83fff8;
      border-radius: 8px;
      padding: 1rem 4rem;
      border-width: 0px;
      width: 171px;
      height: 56px;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #2a2d5b;
      background: #83fff8;
      border-radius: 8px;
      padding: 1rem 4rem;
      border-width: 0px;
      width: 277px;
    }
  }

  &__itemList {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: $itemListBp) {
      margin-top: 1.5rem;
      flex-direction: column;
    }

    @media (max-width: 576px) {
      padding-bottom: 5rem;
    }

    &--item:hover {
      cursor: pointer;
    }

    &--itemSelected:hover {
      cursor: pointer;
    }

    &--itemSold:hover {
      cursor: not-allowed;
    }

    &--item {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      width: 256px;
      height: 291px;
      background: #191a47;
      border: 1px solid #2d2f5e;
      border-radius: 16px;
      margin-bottom: 3rem;
      position: relative;

      &__discount {
        position: absolute;
        height: 70px;
        width: 80px;
        top: -25px;
        left: -30px;

        @media (max-width: 576px) {
          height: 40px;
          width: 50px;
          top: -10px;
          left: -15px;
        }

        &-price {
          text-decoration: line-through;
          margin-right: 20px;
        }
      }

      &__price {
        &-container {
          display: flex;
          justify-content: center;
        }
      }

      @media (max-width: $itemListBp) {
        width: 100%;
        height: 112px;
        max-width: 500px;
        margin-right: 0rem;
        margin-bottom: 0.5rem;
        flex-direction: row;
        align-items: center;
        justify-self: unset;
        opacity: 0.2;
      }

      &--icon {
        width: 256px;
        height: 70%;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $itemListBp) {
          width: 30%;
          height: 112px;
        }

        img {
          object-fit: cover;
          overflow: hidden;
        }
      }

      &--title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        width: 256px;
        height: 30%;
        font-family: 'Inter';
        font-style: normal;
        text-align: center;
        background: linear-gradient(180deg,
            rgba(25, 26, 71, 0) 12.61%,
            #6eeae2 139.89%);
        opacity: 0.6;
        border-radius: 0px 0px 15px 15px;

        @media (max-width: $itemListBp) {
          width: 70%;
          height: 112px;
          text-align: left;
          margin-left: 1rem;
          background: transparent;
        }

        border-radius: 0px 0px 16px 16px;

        h3 {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;

          @media (max-width: $itemListBp) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }

    &--itemSold {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      width: 256px;
      height: 291px;
      background: #15162d;
      border: 1px solid #2d2f5e;
      border-radius: 16px;
      margin-bottom: 3rem;

      @media (max-width: $itemListBp) {
        width: 100%;
        height: 112px;
        max-width: 500px;
        margin-right: 0rem;
        margin-bottom: 0.5rem;
        flex-direction: row;
        align-items: center;
        justify-self: unset;
        opacity: 0.2;
      }

      &--icon {
        width: 256px;
        height: 70%;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.4;

        @media (max-width: $itemListBp) {
          width: 30%;
          min-width: 100px;
          height: 112px;
        }

        img {
          object-fit: cover;
          overflow: hidden;
        }
      }

      &--title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        width: 256px;
        height: 30%;
        font-family: 'Inter';
        font-style: normal;
        text-align: center;
        background: linear-gradient(180deg,
            rgba(25, 26, 71, 0) 12.61%,
            #6eeae2 139.89%);
        opacity: 0.4;
        border-radius: 0px 0px 15px 15px;

        @media (max-width: $itemListBp) {
          width: 70%;
          height: 112px;
          text-align: left;
          margin-left: 1rem;
        }

        // background: linear-gradient(180deg, rgba(25, 26, 71, 0) 12.61%, #6EEAE2 211.3%);
        border-radius: 0px 0px 16px 16px;

        h3 {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;

          @media (max-width: $itemListBp) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #c51051;
        }
      }
    }

    &--itemSelected {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      width: 256px;
      height: 291px;
      background: #191a47;
      border: 1px solid #2d2f5e;
      border-radius: 16px;
      margin-bottom: 3rem;
      border: 3px solid #83fff8;
      box-shadow: 0px 4px 12px #6eead4;
      position: relative;
      background: linear-gradient(180deg,
          rgba(25, 26, 71, 0) 12.61%,
          #6eeae2 149.13%);

      @media (max-width: $itemListBp) {
        width: 100%;
        height: 112px;
        max-width: 500px;
        margin-right: 0rem;
        margin-bottom: 0.5rem;
        flex-direction: row;
        align-items: center;
        justify-self: unset;
      }

      &--icon {
        align-items: center;
        justify-content: center;
      }

      &--title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        width: 256px;
        height: 115px;
        font-family: 'Inter';
        font-style: normal;
        text-align: center;
        background: linear-gradient(180deg,
            rgba(25, 26, 71, 0) 12.61%,
            #6eeae2 211.3%);
        border-radius: 0px 0px 16px 16px;

        @media (max-width: $itemListBp) {
          text-align: left;
        }

        h3 {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;

          @media (max-width: $itemListBp) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #83fff8;
        }
      }
    }
  }
}