.vstaking__status img {
  width: 100%;
}
.vstaking__status-item div {
  background: #182252;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 10px 12px;
  border-radius: 6px;
  margin-left: 0.6px;
}
.border_gradiant {
  background: linear-gradient(
    to right,
    rgba(91, 134, 255, 0.26),
    rgba(213, 21, 254, 0.26)
  );
  width: 100%;
  height: fit-content;
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 0.6px;
  margin-left: 0.5px;
}
