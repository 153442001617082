.swap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;

  .modal {
    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #00000060;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
    }

    &_content {
      background-color: #232449;
      border-radius: 2rem;
      max-width: 40rem;
      color: #fff;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      &_button {
        margin-top: 2rem;
        background-color: #83fff8;
        padding: 1rem 3rem;
        border-radius: 1rem;
      }
    }
  }

  .hackless-swapper {
    max-height: unset;
  }

  .hackless-swapper {
    --color-active: #83fff8;
    min-width: unset;
    max-width: 100%;

    &__content {
      background-color: #232449;
    }

    .h {
      &-dropdown {
        position: relative;

        &__list {
          position: absolute;
          right: -140px;
        }
      }

      &-figure {
        --base-color-background-hover: #83fff8;

        &--base,
        &--0-1-0-1,
        &__content {
          clip-path: none;
          --base-color-background: #06071b;
        }
      }

      &-button {
        &--figure {
          clip-path: none;
        }

        &--figure,
        &__activator {
          --base-color-background: #83fff8;
        }

        &--disabled {
          --base-color-text: #00000086;
        }

        &--circle {
          --base-color-background: #83fff8;
        }
      }

      &-buttons-group__activator.active {
        background-color: #83fff8;
      }
    }
  }
}
