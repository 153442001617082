@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 1rem;

  @media (max-width: 576px) {
    padding: 2rem 1rem;
    height: 300px;
  }

  img {
    height: 16px;
  }

  width: 100%;
  padding: 0 24px 3rem;

  &__social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 200px;
    margin-top: 1.5rem;

    @media (max-width: 576px) {
      width: unset;
      margin-bottom: 20px;
    }

    &--socialIcon {
      color: #76779a;
      width: 27px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: right;
      width: 100%;

      @media (max-width: 576px) {
        margin-right: 20px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1rem;

    &__links {
      display: flex;
      margin-bottom: 1rem;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      a {
        margin-right: 2rem;
        color: #76779a;

        &:hover {
          color: white;
        }
      }
    }

    img {
      margin-bottom: 10px;
      width: 148px;
    }

    h6 {
      font-family: 'Inter';
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: white;
      opacity: 0.3;

      margin-bottom: 0;
    }

    &-second {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: white;

      opacity: 0.3;
    }
  }
}
